import React from 'react';
import './Footer.css'; 

const Footer = () => {
  return (
      <footer className="footer">
          <div className="footer-content">
              <span>Desenvolvido por Fantastsoft</span>
          </div>
      </footer>
  );
};

export default Footer;
