import React, { useState, useEffect, useContext } from "react";
import "./CategoryMenu.css";
import { postDataWithHash } from "../../apiUtils";
import { catalogoContext, imagemOcultaContext } from "../../App";

const CategoryMenu = ({ cnpj }) => {
  const [options, setOptions] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  // const [isSticky, setIsSticky] = useState(false);
  const { catalogo, updateCatalogo } = useContext(catalogoContext);
  const { imagemOculta, updateImagemOculta } = useContext(imagemOcultaContext);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const shouldBeSticky = window.scrollY > 100;
  //     setIsSticky(shouldBeSticky);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!cnpj || !catalogo) {
        return; // Make sure we have necessary data
      }

      const apiUrl = `https://cardapio.api.fantastsoft.com/catalogo/${cnpj}/${catalogo}`;
      try {
        const response = await postDataWithHash(cnpj, apiUrl, {});
        const adaptedOptions = response.map((category) => ({
          codigo: category.codigo,
          descricao: category.descricao,
        }));
        setOptions(adaptedOptions);
        if (adaptedOptions.length > 0) {
          setSelectedId(adaptedOptions[0].codigo); // Automatically select the first category
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, [catalogo]);

  const scrollTo = async (id) => {
    const element = document.getElementById(id);
    const elemHeader = document.getElementById("header");
    const elemCatalog = document.getElementById("catalog");
    const elemImagem = document.getElementById("cover-image");
    const elemListaItens = document.getElementById("lista-itens");
    if (element) {
      const rolar = async () => {
        const offsetTop =
          element.getBoundingClientRect().top + window.pageYOffset;
        const heightHeader = elemHeader.getBoundingClientRect().height;
        const topCatalog = elemCatalog.getBoundingClientRect().top;
        const heightCatalog = elemCatalog.getBoundingClientRect().height;
        const topImagem = elemImagem ? elemImagem.getBoundingClientRect().top : 0;
        const oculta = topImagem < 0;
        const heightImagem = elemImagem
          ? elemImagem.getBoundingClientRect().height
          : 0;
        const offset =
          oculta || offsetTop >= heightImagem
            ? (!oculta && elemImagem ? heightImagem : 0) +
              (heightHeader + heightCatalog)
            : 0;
        //   const offset = isSticky ? (elemHeader ? elemHeader.getBoundingClientRect().height : 0) + (elemCatalog ? elemCatalog.getBoundingClientRect().height : 0) : 0; // Ajusta o deslocamento se a barra for fixa
        //   window.scrollTo({
        //     top: offsetTop - offset, // Aplica o deslocamento de 150px se isSticky for verdadeiro
        //     behavior: "smooth",
        //   });

        const isMobile = window.innerWidth <= 768;

        const toTop = offsetTop - offset;

        console.log(
          `imagemOculta: ${oculta} topImagem: ${topImagem} offsetTop: ${offsetTop} offset: ${offset} heighCatalog: ${heightCatalog} heighImagem: ${heightImagem} scrollTo.top: ${toTop}`
        );

        window.scrollTo({
          top: toTop < heightCatalog ? heightCatalog * -1 : toTop, // Aplica o deslocamento de 150px se isSticky for verdadeiro
          behavior: "smooth",
        });
      };
      await rolar();
    }
  };

  return (
    <div
      className={imagemOculta ? "menu-selector" : "menu-selector"} // mobile-sticky
    >
      <div className="scroll-menu">
        {options.map((option) => (
          <button
            key={option.codigo}
            className={`menu-button ${
              option.codigo === selectedId ? "active" : ""
            }`}
            onClick={() => {
              scrollTo(`card_${option.codigo}`);
              setSelectedId(option.codigo);
              setTimeout(() => {
                scrollTo(`card_${option.codigo}`);
              }, 500);
            }}
          >
            {option.descricao}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CategoryMenu;
