import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import CoverImage from "./components/CoverImage/CoverImage";
import CatalogSelector from "./components/CatalogSelector/CatalogSelector";
import Footer from "./components/Footer/Footer";
import { postDataWithHash } from "./apiUtils";
import ListaMenu from "./components/ListaMenu/ListaMenu";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

export const catalogoContext = createContext();
export const imagemOcultaContext = createContext();
export const produtosContext = createContext();
export const produtosFiltroContext = createContext();

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const cnpj = query.get("empresa");
  const initialCatalogId = query.get("cardapio");
  const [companyInfo, setCompanyInfo] = useState(null);
  const [catalogo, setCatalogo] = useState(initialCatalogId || "");
  const [imagemOculta, setImagemOculta] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [produtos, setProdutos] = useState([]);
  const [produtosFiltro, setProdutosFiltro] = useState([]);

  const updateCatalogo = (novoCatalogo) => {
    setCatalogo(novoCatalogo);
  };

  const updateImagemOculta = (novoImagemOculta) => {
    setImagemOculta(novoImagemOculta);
  };

  const updateProdutos = (novoProduto) => {
    setProdutos(novoProduto);
  };

  const updateProdutosFiltro = (novoProdutoFiltro) => {
    setProdutosFiltro(novoProdutoFiltro);
  };

  useEffect(() => {
    if (!cnpj) {
      setErrorLoading(true);
      return;
    }
    const fetchCompanyInfo = async () => {
      const apiUrl = `https://cardapio.api.fantastsoft.com/empresa/${cnpj}`;
      try {
        const response = await postDataWithHash(cnpj, apiUrl, {});
        setCompanyInfo(response);
        setErrorLoading(false);
      } catch (error) {
        console.error("Erro ao carregar dados da empresa:", error);
        setErrorLoading(true);
      }
    };

    fetchCompanyInfo();
  }, []); // Include initialCatalogId as a dependency

  return (
    <div className="App">
      {errorLoading ? (
        <div className="error-container text-center">
          <img src="/erro.png" alt="Error" />
          <p>Não foi possível carregar os dados da empresa.</p>
        </div>
      ) : (
        <catalogoContext.Provider value={{ catalogo, updateCatalogo }}>
          <imagemOcultaContext.Provider
            value={{ imagemOculta, updateImagemOculta }}
          >
            <produtosContext.Provider value={{ produtos, updateProdutos }}>
              <produtosFiltroContext.Provider
                value={{ produtosFiltro, updateProdutosFiltro }}
              >
                <div className="topo">
                  <Header
                    title={
                      companyInfo
                        ? companyInfo.nomeFantasia
                        : "Empresa Indisponível"
                    }
                  />

                  {companyInfo && companyInfo.imagem ? (
                    <CoverImage imageUrl={companyInfo.imagem} />
                  ) : null}

                  <CatalogSelector
                    // catalogo={catalogo}
                    // onChange={handleCatalogChange}
                    cnpj={cnpj}
                  />
                </div>
                <div id="lista-itens">
                  {/* <div style={{ maxHeight: '500px', overflowY: 'auto' }}> */}
                  <ListaMenu
                    // catalogo={catalogo}
                    cnpj={cnpj}
                  />
                </div>

                <Footer />
              </produtosFiltroContext.Provider>
            </produtosContext.Provider>
          </imagemOcultaContext.Provider>
        </catalogoContext.Provider>
      )}
    </div>
  );
};

export default App;
