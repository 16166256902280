import React, { useState, useEffect, useContext } from "react";
import { postDataWithHash } from "../../apiUtils";
import "./ListaMenu.css";
import {
  catalogoContext,
  produtosContext,
  produtosFiltroContext,
} from "../../App";

const ListaMenu = ({ cnpj }) => {
  const [loading, setLoading] = useState(true);
  const [layoutMode, setLayoutMode] = useState(1); 
  const { catalogo, updateCatalogo } = useContext(catalogoContext);
  const { produtos, updateProdutos } = useContext(produtosContext);
  const { produtosFiltro, updateProdutosFiltro } = useContext(produtosFiltroContext);

  useEffect(() => {
    const fetchProducts = async () => {
      if (cnpj && catalogo) {
        try {
          const url = `https://cardapio.api.fantastsoft.com/produto/${cnpj}/${catalogo}`;
          const response = await postDataWithHash(cnpj, url, {});

          if (response) {
            const adaptedOptions = response.map((value) => ({
              categoriaId: value.catalogo,
              categoria: value.catalogo_descricao,
              produtos: value.produtos.map((produto) => ({
                codigo: produto.codigo,
                descricao: produto.descricao,
                observacao: produto.observacao || " -- ",
                preco: produto.preco.toFixed(2),
                imagem: produto.imagem ? `https://cardapio.api.fantastsoft.com${produto.imagem}` : "/indisponivel.jpg",
              })),
            }));
            updateProdutos(adaptedOptions);
            updateProdutosFiltro(adaptedOptions);
          } else {
            console.log("No data received");
            updateProdutos([]);
            updateProdutosFiltro([]);
          }
        } catch (error) {
          console.error("Failed to fetch products:", error);
        } finally {
          setLoading(false); 
        }
      } else {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchProducts();
  }, [catalogo]);

  return (
    <div>
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <div>
          {produtosFiltro.map((reg) => (
            <div className="categoria" id={`card_${reg.categoriaId}`} key={reg.categoriaId}>
              <h4 className="titulo-categoria">
                <b>{" >>"} {reg.categoria}</b>
              </h4>
              <div className="product-list">
                {reg.produtos.map((produto) => (
                  <div className={layoutMode === 0 ? "product-item" : "product-item-1"} key={produto.codigo}>
                    <div className={layoutMode === 0 ? "product-item-details" : "product-item-details-1"}>
                      <h4 className={layoutMode === 0 ? "product-title" : "product-title-1"}>{produto.descricao}</h4>
                      <p className="product-item-obs">{produto.observacao}</p>
                      <div className={layoutMode === 0 ? "product-item-price" : "product-item-price-1"}>R$ {produto.preco}</div>
                    </div>
                    <div className={layoutMode === 0 ? "product-img" : "product-img-1"}>
                      <img src={produto.imagem} alt={produto.descricao} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListaMenu;
