import React from 'react';
import './CoverImage.css';

const CoverImage = ({ imageUrl }) => {
    return (
        <div className="cover-image" id="cover-image">
            {/* Verifica se imageUrl é não nulo e exibe a imagem */}
            {imageUrl ? <img src={imageUrl} alt="Imagem de Capa" /> : <p>Imagem carregando...</p>}
        </div>
    );
};
export default CoverImage;
