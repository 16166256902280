import React, { useState, useContext } from "react";
import "./SearchBar.css"; // Importing the CSS for styling
import { produtosContext, produtosFiltroContext } from "../../App";

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { produtos, updateProdutos } = useContext(produtosContext);
  const { produtosFiltro, updateProdutosFiltro } = useContext(
    produtosFiltroContext
  );

  // const handleChange = (event) => {
  //   const valor = event.target.value;
  //   if (valor.length === 0) {
  //     updateProdutosFiltro(produtos);
  //   } else {
  //     const prods = produtos.map((reg) => {
  //       return reg.produtos.filter((produto) => {
  //         if (produto.descricao.toLowerCase().includes(valor.toLowerCase())) {
  //           console.log(produto.descricao);
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       });
  //     });
  //     console.log(JSON.stringify(prods));
  //     updateProdutosFiltro(prods);
  //   }
  //   setSearchTerm(valor);
  // };

  const handleChange = (event) => {
    const valor = event.target.value;
    setSearchTerm(valor); // Atualiza o termo de busca antes do condicional para garantir o estado correto

    if (valor.length === 0) {
      updateProdutosFiltro(produtos);
    } else {
      const prods = produtos.flatMap((reg) => {
        // FlatMap irá achatar os resultados do filtro em uma única matriz
        return reg.produtos.filter((produto) => {
          if (produto.descricao.toLowerCase().includes(valor.toLowerCase())) {
            console.log(produto.descricao);
            return true;
          } else {
            return false;
          }
        });
      });
      console.log(JSON.stringify(prods));
      updateProdutosFiltro(prods);
    }
  };

  const handleClear = () => {
    updateProdutosFiltro(produtos);
    setSearchTerm("");
  };

  return (
    <div className="search-bar">
      <input
        className="search-input"
        type="text"
        value={searchTerm}
        onChange={handleChange}
        placeholder="Buscar produtos por nome ou descrição."
      />
      {searchTerm && (
        <button className="search-clear-button" onClick={handleClear}>
          ×
        </button>
      )}
    </div>
  );
};

export default SearchBar;
