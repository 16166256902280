import React, { useState, useEffect, useContext } from 'react';
import SearchBar from '../SearchBar/SearchBar';
import CategoryMenu from '../CategoryMenu/CategoryMenu';
import { postDataWithHash } from '../../apiUtils';
import './CatalagSelector.css';
import { catalogoContext, imagemOcultaContext } from '../../App';

// const CatalogSelector = ({ catalogo, onChange, cnpj }) => {
const CatalogSelector = ({ cnpj }) => {
  const [options, setOptions] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  // const [isSticky, setIsSticky] = useState(false);
  const {catalogo, updateCatalogo} = useContext(catalogoContext);
  const {imagemOculta, updateImagemOculta } = useContext(imagemOcultaContext);

  useEffect(() => {
    const handleScroll = () => {
      const elemImagem = document.getElementById("cover-image");
      const shouldBeSticky = window.scrollY > (window.scrollY > elemImagem ? elemImagem.getBoundingClientRect().height : 0);
      console.log(shouldBeSticky);
      updateImagemOculta(shouldBeSticky);
      // setIsSticky(shouldBeSticky);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchCatalogs = async () => {
      if (!cnpj) {
        return;
      }

      const baseURL = `https://cardapio.api.fantastsoft.com/cardapio/${cnpj}`;
      try {
        const response = await postDataWithHash(cnpj, baseURL, {});
        const adaptedOptions = response.map(catalog => ({
          id: catalog.codigo,
          value: catalog.codigo,
          label: catalog.descricao
        }));
        setOptions(adaptedOptions);
        const catalogoSel = catalogo && catalogo.length > 0 ? catalogo : adaptedOptions.length > 0 ? adaptedOptions[0].value : '';
        setSelectedId(catalogoSel);
        updateCatalogo(catalogoSel);
      } catch (error) {
        console.error('Error fetching catalogs:', error);
      }
    };

    fetchCatalogs();
  }, []);

  const handleSelectionChange = (event) => {
    const newSelectedId = event.target.value;
    setSelectedId(newSelectedId);
    updateCatalogo(newSelectedId);
  };

  return (
    <div id="catalog" className={imagemOculta ? "top mobile-sticky" : "top"}>
      <div className="row fixed">
        <div className="col-xs-12 col-6">
          <select className="form-select selection-cardapio" value={selectedId} onChange={handleSelectionChange}>
            {options.map(option => (
              <option key={option.id} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        <div className="col-xs-12 col-6">
          <SearchBar />
        </div>
      </div>     
      <CategoryMenu 
        cnpj={cnpj} 
        // catalogo={selectedId} 
      />
    </div>
  );
};

export default CatalogSelector;
