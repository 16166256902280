import axios from 'axios';
import { MD5 } from 'crypto-js';

const postDataWithHash = async (cnpj, apiUrl, data) => {
  const now = new Date();
  const formattedDateTime = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}T${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}.${now.getMilliseconds().toString().padStart(3, '0')}`;

  const headers = {
    'client-date-time': formattedDateTime
  };

  const dateString = `${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
  const hashInput = cnpj + dateString;
  const hash = MD5(hashInput).toString();

  const requestBody = {
    ...data,
    hash: hash
  };

  try {
    const response = await axios.post(apiUrl, requestBody, { headers: headers });
    return response.data;
  } catch (error) {
    console.error('Erro ao enviar dados:', error);
    throw error;
  }
};

export { postDataWithHash };
